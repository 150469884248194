@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.instruction-icon {
  display: flex;
  justify-content: center;
}
.instructions {
  background-color: var(--color-base-background);
}

.instruction-card {
  background-color: var(--color-secondary);
}
