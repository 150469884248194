@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.samples {
  background-color: var(--color-base-background);
}

.progress-divider {
  border-width: 2px;
  border-radius: 25px;
}
.completed {
  border-color: var(--color-success);
}
.empty-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(var(--grid-gutter) / 2);
  &:last-child {
    margin-right: 0;
  }
}
